import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Layout/LayoutView.vue'
const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;
const routes = [
  {
    path: '/',
    meta: { requiresAuth: true },
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '',
        meta: { requiresAuth: true },
        name: 'Dashboard',
        component: () => import('../views/Layout/CredentialView.vue')
      },
      {
        path: 'exit_pass',
        name: 'pase_salida',
        component: () => import('../views/Layout/ExitPassView.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: { requiresAuth: false },
    component: () =>  import('../views/NotFoundView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isAuthenticated() {
  let loggedIn = JSON.parse(localStorage.getItem(NAME_APP));
  if (loggedIn === null) {
    return false
  }
  else {
    return true
  }
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.path != '/login' && isAuthenticated()) {
      next();
    }
    else {
      router.replace({ path: '/login' })
    }
  }
  else {
    next()
  }
})
export default router
