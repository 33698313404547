// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify(
  {
    components,
    directives,
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'bcTheme',
      themes: {
        bcTheme: {
          dark: false,
          variables: {},
          colors: {
            background: '#fbfbfb',
            surface: '#212121',
            primary: '#012a4c',
            'primary-darken-1': '#0E619F',
            'primary-light-1': '#023859',
            secondary: '#eff2eb',
            darken: '#000000',
            gold: '#f0a500',
            error: '#CF6679',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00'
          }
        }
      }
    }
  }
)
