<template>
    <div>
        <v-toolbar app dark :image="require('@/assets/images/bg/12.jpg')">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>Credencial Digital</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="logout">
                <v-icon>mdi-export</v-icon>
            </v-btn>
        </v-toolbar>
        <v-layout fluid>
            <v-navigation-drawer v-model="drawer" temporary>
                <v-list-item v-if="employee" :prepend-avatar="profile" :title="employee.user.name"></v-list-item>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <v-list-item prepend-icon="mdi mdi-qrcode-edit" title="Inicio" to="/"></v-list-item>
                    <v-list-item prepend-icon="mdi mdi-card-account-details-outline" title="Pase salida" to="exit_pass"></v-list-item>
                    <!-- <v-list-item prepend-icon="mdi-forum" title="Requisición" to="requisition"></v-list-item> -->
                    <v-list-item prepend-icon="mdi-forum" title="Mensajes" to="messages"></v-list-item>
                    <v-list-item prepend-icon="mdi-forum" title="Avisos" value="notices"></v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list-item link prepend-icon="mdi-view-dashboard" title="Cerrar Sesion" to="/logout"></v-list-item>
            </v-navigation-drawer>

            <router-view/>



        </v-layout>

    </div>
</template>
<script>
const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;
export default {
    data: () => ({
        drawer: null
    }),
    methods: {
        logout() {
            this.uStore.logout().then((r) => {
                this.$router.replace("login")
            })
        }
    },
}
</script>
<style>
.profile-txt {
    text-align: center !important;
}

.profile {
    border-radius: 15px;
    box-shadow: 10px 10px 5px grey;
    width: 150px;
    align-self: center !important;
    text-align: center !important;
}

.t18{
    font-size: 18px !important;
}
</style>