import axios from 'axios';

const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;


export default function setup() {
    axios.interceptors.request.use(function (config) {
        const dt = JSON.parse(localStorage.getItem(NAME_APP));
        console.log("token ", dt)
        if (dt.access_token) {
            config.headers.Authorization = `Bearer ${dt.access_token}`;
            config.headers.Requesterby = dt.user && dt.user.name ? `${dt.user.uuid},${dt.user.name},${dt.user.email},${btoa(dt.user.depto)}` : "anonymous";
        }
        else {
            console.log("end")
           // commons.setLoader.hide()
        }
        return config

    }, function (err) {
        //commons.setLoader.hide()
        return Promise.reject(err);
    });


    axios.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201) {
                //commons.setLoader.hide()
                return Promise.resolve(response);
            } else {
                //commons.setLoader.hide()
                return Promise.reject(response);
            }
        },
        error => {
            if (error && error.response && error.response.status) {
                switch (error.response.status) {
                    case 400:
                        // if (error.response.data && error.response.data.error && error.response.data.error == "token is invalid") {
                        //   //store.dispatch("authenticate/notify_token_invalid");
                        // }
                        // else if (error.response.data && error.response.data.error && error.response.data.error == "token is expired") {
                        //   //store.dispatch("toasts/notify_need_authenticate");
                        // }
                        console.log("error 400 " + error.response.statusText);
                        break;
                    case 401:
                        console.log("error 401 " + error.response.statusText);
                        break;
                    case 403:
                        console.log("error 403 " + error.response.statusText);
                        break;
                    case 404:
                        console.log("error 404 " + error.response.statusText);
                        break;
                    case 500:
                        console.log("error 500 " + error.response.statusText);
                        break;
                    case 502:
                        console.log("error 502 " + error.response.statusText);
                }
                //commons.setLoader.hide()
                return Promise.reject(error.response);
            }
            // else if(error && error.message){
            //     // store.dispatch("toasts/notify_error", error.message);
            //     // commons.setLoader.hide()
            // }
            // else {
            //     //commons.setLoader.hide()
            // }
        }
    );
}
